import React, { useEffect, useState } from 'react';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { propTypes } from '../../util/types';

import css from './AlokaAdmin.module.css';
import StaticPage from '../PageBuilder/StaticPage';
import { LayoutComposer } from '../PageBuilder/PageBuilder';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterComponent from '../FooterContainer/FooterContainer';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { useIntl } from '../../util/reactIntl';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { createResourceLocatorString } from '../../util/routes';

import { H2, H3, H5, ListingCard, NamedLink } from '../../components';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { handleToggleFavorites } from '../ListingPage/ListingPage.shared';
import CookieMananger from '../../components/ui-components/cookies/CookieMananger';
import UserOnboarding from '../AuthenticationPage/SignupForm/UserOnboarding/UserOnboarding';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import acctionCRMDate from '../../util/user/acctionCRMData';
import basedPostUrlRequestLogedIn from '../../util/apiRequests/basedPostUrlRequestLogedIn';
import { apiBaseUrl } from '../../util/api';
import DownloadReports from './DownloadReports';

export const LandingPageComponent = props => {
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();

  const { currentUser, onUpdateFavorites, location, history } = props;
  const layoutAreas = `
  topbar
  main
  footer
`;
  console.log('currentUser?.attributes:', currentUser?.attributes);
  if (currentUser == null) {
    return 'laoding...';
  } else if (
    (currentUser?.attributes?.email && !currentUser?.attributes?.email.includes('@aloka.app')) ||
    !currentUser?.attributes?.emailVerified
  ) {
    return 'Not allowed';
  }
  const [message, setMessage] = useState('');
  const [downloadLink, setDownloadLink] = useState('');
  const newApiBaseUrl = apiBaseUrl();

  const handleDownload = async () => {
    try {
      // Notify user that the file is being prepared
      setMessage('Preparing file for download...');

      // Call the API using fetch to download the file
      const response = await fetch(newApiBaseUrl + '/api/download-unverified-users', {
        method: 'GET',
        credentials: 'include', // Include credentials if required
        headers: {
          Accept: 'application/json', // Ensure JSON compatibility
        },
      });

      // Check if the response is successful
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Parse the response as a blob
      const blob = await response.blob();

      // Create a link and trigger download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'unverified_users.xlsx');
      document.body.appendChild(link);
      link.click();

      // Clean up and notify user
      link.remove();
      setMessage('Download complete!');
    } catch (error) {
      console.error('Error downloading file:', error);
      setMessage('Error downloading the file.');
    }
  };
  const handleDownloadUnverifiedIdentityUsers = async () => {
    try {
      setMessage('Preparing file for download...');

      const response = await fetch(newApiBaseUrl + '/api/download-unverified-identity-users', {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'unverified_identity_users.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      setMessage('Download complete!');
    } catch (error) {
      console.error('Error downloading file:', error);
      setMessage('Error downloading the file.');
    }
  };

  return (
    <StaticPage>
      <LayoutComposer areas={layoutAreas} className={css.layout}>
        {props => {
          const { Topbar, Main, Footer } = props;
          return (
            <>
              <Topbar as="header">
                <TopbarContainer
                  className={css.topbar}
                  desktopClassName={css.topbarDesktop}
                  hideSearchForm
                />
              </Topbar>
              <Main as="main" className={css.main}>
                <section>
                  <div className="App">
                    <p>{message}</p>
                    <button onClick={handleDownload}>Download Unverified Users</button>
                    <button onClick={handleDownloadUnverifiedIdentityUsers}>
                      Download Unverified Identity Users
                    </button>
                  </div>
                  <DownloadReports />
                </section>
              </Main>
              <Footer as="footer">
                <FooterComponent />
              </Footer>
            </>
          );
        }}
      </LayoutComposer>
    </StaticPage>
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { currentPageResultIds, queryInProgress, queryPopularError } = state.LandingPage;
  const mostPopularListings = getListingsById(state, currentPageResultIds);
  const { currentUser } = state.user;
  return {
    mostPopularListings,
    queryInProgress,
    queryPopularError,
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateFavorites: payload => dispatch(updateProfile(payload)),
});

const LandingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(LandingPageComponent);

export default LandingPage;
