import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import userOnboardingData from '../../../../constants/userOnboarding.json';

import { FormattedMessage, intlShape, injectIntl } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { FieldTextInput, IconReviewUser, Modal, PrimaryButton } from '../../../../components';

// import ReviewForm from '../ReviewForm/ReviewForm';

import css from './UserOnboarding.module.css';
import { Field, Form } from 'react-final-form';
import { PiDivideDuotone } from 'react-icons/pi';
import basedPostUrlRequestLogedIn from '../../../../util/apiRequests/basedPostUrlRequestLogedIn';

const UserOnboarding = props => {
  const {
    className,
    rootClassName,
    id,
    marketplaceName,
    intl,
    //isOpen,
    //onCloseModal,
    //onManageDisableScrolling,
    onSubmitReview,
    revieweeName,
    reviewSent,
    sendReviewInProgress,
    sendReviewError,
    currentUser,
  } = props;
  const [isOpen, setIsOpen] = useState(true);

  const accountClassification =
    props?.currentUser?.attributes?.profile?.privateData?.accountClassification;
  const { accountType, yearsOfExperience } = accountClassification || {};
  console.log('accountClassification: ', accountType);

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({ id: 'ReviewModal.later' });
  const reviewee = <span className={css.reviewee}>{revieweeName}</span>;
  const onManageDisableScrolling = () => {};
  const onCloseModal = () => {
    setIsOpen(false);
  };

  const audiovisualSectors = [
    { name: 'Select your sector', value: '' },
    { name: 'Cinema', value: 'cinema' },
    { name: 'Television', value: 'television' },
    { name: 'Radio', value: 'radio' },
    { name: 'Podcasting', value: 'podcasting' },
    { name: 'Direction', value: 'direction' },
    { name: 'Production', value: 'production' },
    { name: 'Editing', value: 'editing' },
    { name: 'Photography', value: 'photography' },
    { name: 'Streaming and Video-on-Demand (VoD)', value: 'streaming-and-video-on-demand' },
    { name: 'Music Production and Sound Design', value: 'music-production-and-sound-design' },
    { name: 'Video Games and Interactive Media', value: 'video-games-and-interactive-media' },
    { name: 'Advertising and Marketing', value: 'advertising-and-marketing' },
    { name: 'Animation and Visual Effects (VFX)', value: 'animation-and-visual-effects' },
    { name: 'Corporate and Institutional Media', value: 'corporate-and-institutional-media' },
    { name: 'Media Technology and Infrastructure', value: 'media-technology-and-infrastructure' },
    { name: 'Events and Live Productions', value: 'events-and-live-productions' },
  ];
  // const workTypes = [
  //   { name: 'Select work types', value: '' },
  //   { name: 'Full-time', value: 'full-time' },
  //   { name: 'Part-time', value: 'part-time' },
  //   { name: 'Freelance', value: 'freelance' },
  // ];
  const accountTypes = [
    { name: 'Select account type', value: '' },
    { name: 'Personal', value: 'personal' },
    { name: 'Company', value: 'company' },
  ];
  const onSubmite = async accountClassification => {
    basedPostUrlRequestLogedIn('/api/update-user-data', { accountClassification });
  };
  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      <Form
        initialValues={accountClassification}
        onSubmit={e => {
          e.preventDefault();
        }}
        render={({ handleSubmit, values }) => {
          return (
            <form
              onSubmit={e => {
                e.preventDefault();
                onSubmite(values);
              }}
            >
              <div>
                <label>Account type</label>
                <Field name="accountType" component="select">
                  {userOnboardingData.accountTypes.data.map(item => (
                    <option key={item.value} value={item.value}>
                      {item.name}
                    </option>
                  ))}
                </Field>
              </div>

              <br />
              <div>
                <label>Audiovisual Sectors</label>
                <Field name="audiovisualSector" component="select">
                  {audiovisualSectors.map(item => (
                    <option key={item.value} value={item?.value}>
                      {item.name}
                    </option>
                  ))}
                </Field>
              </div>
              <br />
              {/* <div>
                <label>Disponibilidad</label>
                <Field name="workType" component="select">
                  {userOnboardingData.availability.data.map(item => (
                    <option key={item.value} value={item.value}>
                      {item.name}
                    </option>
                  ))}
                </Field>
              </div>

              <br /> */}
              <FieldTextInput
                className={css.reviewContent}
                type="number"
                min={0}
                max={100}
                id={'Years of experience in the sector'}
                name="yearsOfExperience"
                label={'Years of experience in the sector'}
                placeholder={'Years of experience in the sector'}
                value={yearsOfExperience}
              />
              <br />

              <PrimaryButton
                type="submit"
                //   inProgress={inProgress}
                //   ready={pristineSinceLastSubmit}
                //   disabled={submitDisabled}
              >
                <FormattedMessage id="ContactDetailsForm.saveChanges" />
              </PrimaryButton>
            </form>
          );
        }}
      ></Form>
    </Modal>
  );
};

const { bool, string } = PropTypes;

UserOnboarding.defaultProps = {
  currentUser: null,
  className: null,
  rootClassName: null,
  reviewSent: false,
  sendReviewInProgress: false,
  sendReviewError: null,
};

UserOnboarding.propTypes = {
  currentUser: propTypes.currentUser,
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  reviewSent: bool,
  sendReviewInProgress: bool,
  sendReviewError: propTypes.error,
  marketplaceName: string.isRequired,
};

export default injectIntl(UserOnboarding);
