import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import './react-datepicker.css';

const DatePickerComponent = props => {
  const [selectedDate, setSelectedDate] = useState(null);

  return (
    <DatePicker
      selected={selectedDate}
      onChange={date => {
        setSelectedDate(date);
        if (typeof props.onChange === 'function') {
          props.onChange(date);
        }
      }}
      dateFormat="dd-MM-yyyy"
      placeholderText="dd-MM-yyyy"
    />
  );
};

export default DatePickerComponent;
