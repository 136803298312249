import React, { useState } from 'react';
import Style from './UnverifiedUsers.module.css';
import { apiBaseUrl } from '../../util/api';

const UnverifiedUsers = () => {
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState('');
  const [fromDate, setFromDate] = useState('2022-01-01');
  const [toDate, setToDate] = useState('2025-01-01');
  const [emailVerified, setEmailVerified] = useState('all');
  const [identityVerified, setIdentityVerified] = useState('all');
  const [stripeConnected, setStripeConnected] = useState('all');
  const newApiBaseUrl = apiBaseUrl();

  const fetchUsers = async () => {
    try {
      setMessage('Fetching unverified users...');

      const url = new URL(`${newApiBaseUrl}/api/unverified-users`);
      url.searchParams.append('fromDate', fromDate);
      url.searchParams.append('toDate', toDate);
      if (emailVerified !== 'all') url.searchParams.append('emailVerified', emailVerified);
      if (identityVerified !== 'all') url.searchParams.append('identityVerified', identityVerified);
      if (stripeConnected !== 'all') url.searchParams.append('stripeConnected', stripeConnected);

      const response = await fetch(url.toString(), {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setUsers(data);
      setMessage(
        data.length > 0
          ? `Results Found ${data.length}.`
          : 'No results found for the selected filters.'
      );
    } catch (error) {
      console.error('Error fetching users:', error);
      setMessage('Error fetching users.');
    }
  };

  return (
    <div className={Style.container}>
      <h1 className={Style.title}>Unverified Users</h1>
      <div className={Style['form-group']}>
        <label className={Style['form-label']}>
          From Date:
          <input
            type="date"
            value={fromDate}
            onChange={e => setFromDate(e.target.value)}
            className={Style['form-input']}
          />
        </label>
        <label className={Style['form-label']}>
          To Date:
          <input
            type="date"
            value={toDate}
            onChange={e => setToDate(e.target.value)}
            className={Style['form-input']}
          />
        </label>

        <label className={Style['form-label']}>
          Email Verified:
          <select
            value={emailVerified}
            onChange={e => setEmailVerified(e.target.value)}
            className={Style['form-input']}
          >
            <option value="all">All</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </label>

        <label className={Style['form-label']}>
          Identity Verified:
          <select
            value={identityVerified}
            onChange={e => setIdentityVerified(e.target.value)}
            className={Style['form-input']}
          >
            <option value="all">All</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </label>

        <label className={Style['form-label']}>
          Stripe Connected:
          <select
            value={stripeConnected}
            onChange={e => setStripeConnected(e.target.value)}
            className={Style['form-input']}
          >
            <option value="all">All</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </label>

        <button onClick={fetchUsers} className={Style.button}>
          Fetch Users
        </button>
      </div>
      {message && <p className={Style.message}>{message}</p>}
      {users.length > 0 && (
        <table className={Style.table}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Created At</th>
              <th>Email Verified</th>
              <th>Identity Verified</th>
              <th>Stripe Connected</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user.id}>
                <td>{user.attributes.profile.displayName}</td>
                <td>{user.attributes.email}</td>
                <td>{new Date(user.attributes.createdAt).toLocaleString()}</td>
                <td>{user.attributes.emailVerified ? 'Yes' : 'No'}</td>
                <td>
                  {user?.attributes?.profile?.metadata?.siltUserStatus === 'SUCCESS' ? 'Yes' : 'No'}
                </td>
                <td>{user.attributes.stripeConnected ? 'Yes' : 'No'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default UnverifiedUsers;
